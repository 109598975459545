import React, { Component } from 'react';
import BadlibsActions from '../actions/badlibs';

class Edit extends Component {
  constructor() {
    super();
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
  }

  componentWillMount() {
    this.setState({ lib: this.props.lib });
  }

  handleChange(e) {
    let lib = this.state.lib;
    lib.text = e.target.value;
    this.setState({ lib });
  }

  handleTitleChange(e) {
    let lib = this.state.lib;
    lib.title = e.target.value;
    this.setState({ lib });
  }


  handleSave() {
    if (this.state.lib._id) {
      BadlibsActions.updateLib(this.state.lib);
    } else {
      BadlibsActions.createLib(this.state.lib)      
    }
  }

  handleCancel() {
    BadlibsActions.cancelEdit();
  }


  render() {
    if (!this.state) return null;

    return (
      <div className="Edit">
        <div className="EditElement">
          <div className="Instructions">
            To create a variable part of speech, place the part of speech in double-curly braces. E.g. "My shirt is very &#123;&#123;adjective&#125;&#125;."
          </div>
          <label>Title:
            <input className="EditLib-title" type="text" onChange={this.handleTitleChange} value={this.state.lib.title ? this.state.lib.title : ''} />
          </label>
        </div>
        <div className="EditElement">
          <label>Badlib:
            <textarea className="EditLib" onChange={this.handleChange} value={this.state.lib.text ? this.state.lib.text : ''}></textarea>
          </label>
        </div>
        <button onClick={this.handleSave}>Save</button>
        <button onClick={this.handleCancel}>Cancel</button>
      </div>
    );
  }
}

export default Edit;
