import React, { Component } from 'react';
import BadlibsActions from '../actions/badlibs';
import BadlibsStore from '../stores/badlibs';
import Edit from './edit';
import Play from './play';
import Libs from './libs';
import '../css/badlibs.css'


class Controller extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  onChange(status) {
    if(status.libs) {
      this.setState({ libs: status.libs });
    }
    if (status.editLib) {
      this.setState({ editLib: status.editLib, createLib: null, playLib: null });
    }
    if (status.createLib) {
      this.setState({ createLib: status.createLib, editLib: null, playLib: null });
    }
    if (status.playLib) {
      this.setState({ playLib: status.playLib, editLib: null, createLib: null });
    }
    if (status.playedLib) {
      this.setState({ playedLib: status.playedLib, playLib: null, editLib: null, createLib: null });
    }
    if (status.cancelEdit) {
      this.setState({ playedLib: null, playLib: null, editLib: null, cancelEdit: null, createLib: null });
      BadlibsActions.allLibs({});
    }
  }

  componentWillMount() {
    this.unlisten = BadlibsStore.listen(this.onChange);
    BadlibsActions.allLibs({});
  }

  handleCreate() {
    BadlibsActions.setCreateLib();
  }


  render() {
    if (!this.state) {
      return null;
    }
    return (
      <div className="Controller">
        <button onClick={this.handleCreate}>Create Lib</button>
        {this.state.editLib ? <Edit lib={this.state.editLib} /> : null}
        {this.state.createLib ? <Edit lib={this.state.createLib} /> : null}
        {this.state.playLib ? <Play lib={this.state.playLib} /> : null}
        {this.state.libs ? <Libs libs={this.state.libs} /> : null}
      </div>
    );
  }
}

export default Controller;
