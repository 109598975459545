import React, { Component } from 'react';
import BadlibsActions from '../actions/badlibs';

class Play extends Component {

  parseLib(lib) {
    const re = /{{[A-Za-z].*?}}/;
    let splits = lib.split(re);

    let pos = [];
    let index = 0;
    while (lib.match(re) && lib.match(re).index > 0) {
      pos[index] = {'partOfSpeech': lib.match(re)[0].replace('{{', '').replace('}}',''), index: index};
      lib = lib.slice(lib.match(re).index + lib.match(re)[0].length)
      index++;
    }
    pos = this.getSuggestions(pos);

    let joinedLib = '';
    let vowels = ['a','e','i','o','u']
    for (let i = 0; i < splits.length; i++) {

      switch (splits[i].slice(-3)) { // swap 'a' and 'an'
        case ' a ':
          if (pos[i] && (vowels.indexOf(pos[i].suggestion.slice(0, 1))) > -1) {
            splits[i] = splits[i].slice(0, -3) + ' an ';
          }
          break;
        case 'an ':
          if (pos[i] && (vowels.indexOf(pos[i].suggestion.slice(0, 1))) === -1) {
            splits[i] = splits[i].slice(0, -3) + 'a ';
          }
          break;
        default:
        break
      }
      joinedLib += splits[i];
      if (pos[i]) {
        joinedLib += pos[i].suggestion;
      }
    }

    return joinedLib
  }

// randomly gets suggestions; sorts by index and returns
  getSuggestions(pos) {
    let newPos = []
    let max = pos.length;
    while (max > 0) {
      let index = Math.floor(Math.random() * max);
      pos[index].suggestion = window.prompt("Enter a " + pos[index].partOfSpeech);
      newPos.push(pos[index]);
      pos.splice(index, 1);
      max--;

    }
    newPos.sort((a,b) => { // resort
      if (a.index > b.index) return 1;
      if (b.index > a.index) return -1;
      return 0;
    });
    return newPos
  }

  play(lib) {
    let playedLib = this.parseLib(lib);
    BadlibsActions.setPlayedLib(playedLib);
  }


  render() {
    if (!this.props) return null;
    return (
      <div className="Play">
        <h4>{this.props.lib.title}</h4>
        {this.parseLib(this.props.lib.text)}
      </div>
    );
  }
}

export default Play;
