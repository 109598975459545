import Reflux from 'reflux';

let BadlibsActions = Reflux.createActions([
	"findRatings",
	"setRating",
	"allLibs",
	"getPartsOfSpeech",
	"createLib",
	"updateLib",
	"getLib",
	"deleteLib",

	"setEditLib",
	"setCreateLib",
	"setPlayLib",
	"setPlayedLib",
	"cancelEdit"
]);

export default BadlibsActions;
