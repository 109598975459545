import React, { Component } from 'react';
import './App.css';
import Controller from './components/controller';

class App extends Component {

  render() {
    return (
      <div className="App">
      	<header className="header">
      		<h1>Badlibs</h1>
          <span>Where things get crazy</span>
      	</header>
        <Controller />
      </div>
    );
  }
}

export default App;
