import React, { Component } from 'react';
import BadlibsActions from '../actions/badlibs';

class Lib extends Component {
  constructor() {
    super();
    this.handlePlay = this.handlePlay.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  
  handlePlay() {
    BadlibsActions.setPlayLib(this.props.lib);
  }

  handleEdit() {
    BadlibsActions.setEditLib(this.props.lib);
  }

  handleDelete() {
    if (window.confirm("Are you sure you want to delete " + this.props.lib.title + "?")) {
      BadlibsActions.deleteLib(this.props.lib);
    }
  }

  render() {
    return (
      <div className="Lib">
        
        <button onClick={this.handlePlay}>Play</button>
        <button onClick={this.handleEdit}>Edit</button>
        <button onClick={this.handleDelete}>Delete</button>
        <div className="Lib-title">{this.props.lib.title}</div>
      </div>
    );
  }
}

export default Lib;
