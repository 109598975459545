import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();


//https://www.valentinog.com/blog/react-redux-tutorial-beginners/
//https://medium.com/@tkwebdev/a-quick-intro-to-new-react-context-api-and-why-it-wont-replace-state-management-libraries-3b1def3d4e51