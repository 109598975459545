import Reflux from 'reflux';
import BadlibsActions from '../actions/badlibs';
import { API } from  '../config';


var BadlibsStore = Reflux.createStore({
	listenables: [BadlibsActions],

	setRating(rating) {
		this.trigger({ rating });
	},

	setEditLib(lib) {
		this.trigger({ editLib: lib });
	},

	setCreateLib() {
		this.trigger({ createLib: {} });
	},

	setPlayLib(lib) {
		this.trigger({ playLib: lib });
	},

	setPlayedLib(lib) {
		this.trigger({ playedLib: lib });
	},
	cancelEdit() {
		this.trigger({ cancelEdit: true });
	},

	findRatings: function() {
		fetch(API + "/ratings/find", {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({})
		}).then((resp) => {
			return resp.json();
		}).then((resp) => {
			this.trigger({ ratings: resp });
		});
	},

	allLibs(lib) {
		fetch(API + "/lib/all", {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(lib)
		}).then((resp) => {
			return resp.json();
		}).then((resp) => {
			const libs = resp ? resp : [];
			this.trigger({ libs });
		});
	},

	getPartsOfSpeech() {
		fetch(API + "/partsofspeech/find", {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({})
		}).then((resp) => {
			return resp.json();
		}).then((resp) => {
			this.trigger({ partsOfSpeech: resp });
		});
	},

	createLib(lib) {
		fetch(API + "/lib/create", {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(lib)
		}).then((resp) => {
			return resp.json();
		}).then((resp) => {
			this.trigger({ cancelEdit: true });
		});
	},

	updateLib(lib) {
		fetch(API + "/lib/update", {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(lib)
		}).then((resp) => {
			return resp.json();
		}).then((resp) => {
			this.trigger({ cancelEdit: true });
		});
	},

	deleteLib(lib) {
		fetch(API + "/lib/delete", {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(lib)
		}).then((resp) => {
			return resp.json();
		}).then((resp) => {
			this.trigger({ cancelEdit: true });
		});
	},

	getLib(id) {
		fetch(API + "/lib/get?id=" + id, {
			method: 'GET'
		}).then((resp) => {
			return resp.json();
		}).then((resp) => {
			this.trigger({ libs: resp });
		});
	},

});

export default BadlibsStore;
