import React, { Component } from 'react';
import Lib from './lib';


class Libs extends Component {

  renderLibs() {
    let libs = [];
    this.props.libs.forEach((el) => {
      libs.push(<Lib key={el._id} lib={el} />)
    });
    return libs;
  }


  render() {
    if (!this.props) {
      return null;
    }
    return (
      <div className="Libs">
        {this.renderLibs()}
      </div>
    );
  }
}

export default Libs;
